/*
@contract listing-card {
  min-height: MinHeight
  padding: Padding
  badge:{
    star: {
      color: Color
    }
  }
  border: Border
}
*/

.card {
  --rating-star-size: var(--font-size-md);
  --rating-size: var(--front-size-body);
  --bookmark-filled-background-color: var(
    --listing-card-bookmark-filled-background-color
  );
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-gray-200);
  background: var(--color-white);
  border-radius: var(--border-radius-7x);
  width: var(--listing-card-width);
  padding: var(--listing-card-padding);
  overflow: var(--listing-card-overflow);
  color: var(--color-gray-500);
  position: relative;
  z-index: var(--z-index-0);
  font-size: var(--font-size-body);
  min-height: var(--listing-card-min-height);

  .carousel {
    position: relative;
    height: 240px;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;

    @media (--screen-md) {
      min-width: var(--listing-card-carousel-min-width);
    }
  }
}

.card.carouselTodaysTopPick {
  border: 3px solid var(--color-red-500);
  .carousel {
    border-top-left-radius: var(--border-radius-5x);
    border-top-right-radius: var(--border-radius-5x);
  }
}

.todaysTopPick {
  display: inline-block;
  background-color: var(--color-red-500);
  color: var(--color-white);
  font-size: var(--font-size-sm);
  font-weight: 700;
  height: 38px;
  padding: var(--space-1-5x) var(--space-2-5x) 0 11px;
  border-radius: 4px 4px 0 0;
  position: absolute;
  top: -28px;
  left: -3px;
}

.strong {
  font-weight: bold;
  color: var(--color-black);
}

.priceWrapper {
  align-items: center;
  display: flex;
  gap: var(--space-0-5x);
  min-width: 0;
}

.price {
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-lg);
  color: var(--color-black);
  white-space: nowrap;
}

.badge {
  padding: var(--space-1x);
  background-color: var(--listing-card-badge-background-color);
  border-radius: var(--space-6x);
  color: var(--color-white);
  margin-left: var(--space-1x);
}

.deals {
  min-width: 0;
}

.name {
  font-weight: var(--font-weight-semibold);
  color: var(--color-black);
  text-decoration: none;
}

.basicDetails {
  width: 100%;
  display: flex;
  padding-top: var(--space-2x);
  padding-left: var(--space-3-5x);
  padding-right: var(--space-3-5x);
  justify-content: space-between;
  align-items: center;
  gap: var(--space-2x);
}

.nameAddressSection,
.amenitiesList {
  inset: 0;
  display: block;
  font-size: var(--font-size-body);
}

.absoluteLink {
  text-decoration: none;
  color: var(--color-gray-500);
  margin-top: var(--space-2x);
  padding: var(--space-none) var(--space-3-5x);
  line-height: var(--leading-tight);
  gap: var(--space-1-5x);
  display: flex;
  flex-direction: column;
}

.absoluteLink::after {
  content: '';
  position: absolute;
  inset: 0;
}

.activeFromMap {
  transition: box-shadow 0.3s ease-in-out;
  border-radius: var(--space-3x);
  box-shadow: var(--search-results-li-highlight-box-shadow);
}

.detailButtonsWrapper {
  position: relative;
  z-index: var(--z-index-4x);
  display: flex;
  gap: var(--space-1-5x);
  min-width: 0;
}

.spotlightFeaturedText {
  position: absolute;
  bottom: var(--space-2-5x);
  left: var(--space-2-5x);
  color: var(--color-white);
  z-index: var(--z-index-2x);
  font-size: 13px;
  line-height: var(--leading-tight);
  font-weight: var(--font-weight-semibold);
  text-shadow: 0 2px 2px rgba(0 0 0 / 35%);
  pointer-events: none;
}

.spotlightFeaturedTextDivider {
  padding: 0 var(--space-3x);
}

.categoryBadgeText {
  position: absolute;
  top: var(--space-2-5x);
  left: var(--space-2-5x);
  background: var(--color-white);
  border-radius: var(--space-6x);
  color: var(--color-gray-500);
  font-size: var(--font-size-sm);
  padding: var(--space-1x) var(--space-2-5x);
  line-height: var(--leading-normal);
  font-weight: var(--font-weight-semibold);
  z-index: var(--z-index-3x);
}

.threeDTourText {
  position: absolute;
  top: var(--space-2-5x);
  right: var(--space-2-5x);
  background: var(--color-white);
  z-index: var(--z-index-3x);
  border-radius: var(--space-6x);
  color: var(--color-black);
  font-size: 13px;
  padding: var(--space-1x) var(--space-2-5x);
  font-weight: var(--font-weight-medium);
  line-height: var(--leading-tight);
}

.threeDTourButton {
  position: absolute;
  top: var(--space-2-5x);
  right: var(--space-2-5x);
  z-index: var(--z-index-2x);
  padding: var(--space-1x) var(--space-2-5x);
  line-height: var(--leading-normal);
}

.carouselSection {
  position: relative;
  z-index: var(--z-index-2x);
}

.textBackdrop {
  display: block;
  position: absolute;
  bottom: 0;
  pointer-events: none;
  border-radius: 0 0 var(--border-radius-6x) var(--border-radius-6x);
  height: 35px;
  width: 100%;
  z-index: var(--z-index-1x);
  background: linear-gradient(
    180deg,
    rgb(0 0 0 / 0%) 0%,
    rgb(0 0 0 / 65%) 100%
  );
}

.ctaButtons {
  --button-font-size: var(--font-size-body);
  --button-padding: 0;
  --button-width: 100%;
  --button-gap: var(--space-1x);
  display: flex;
  padding: var(--space-4x) var(--space-3x) var(--space-none);
  gap: var(--space-2x);
  position: relative;
  z-index: var(--z-index-2x);
  & :nth-child(2) {
    --icon-button-width: 100%;
  }
}

@media (--screen-md) {
  .ctaButtons {
    margin-top: auto;
  }
}

.locationBadge {
  z-index: var(--z-index-1x);
  position: absolute;
  right: var(--space-none);
  bottom: var(--space-none);
  pointer-events: auto;
  font-weight: var(--font-weight-semibold);
  display: flex;
}
